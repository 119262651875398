import { SetupContext } from '@vue/composition-api'
import { AxiosInstance, AxiosResponse } from 'axios'
import fileDownload from 'js-file-download'
import mime from 'mime-types'

export default function useAttachment({ root }: Partial<SetupContext>) {
  const axiosInstance = root?.$store.getters['api/getInstance'] as AxiosInstance

  const fileTypes = [
    'text/csv',                                                                 // .csv
    'application/msword',                                                       // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // .docx
    'image/jpeg',                                                               // .jpeg / .jpg
    'application/vnd.oasis.opendocument.spreadsheet',                           // .ods
    'application/vnd.oasis.opendocument.text',                                  // .odt
    'image/png',                                                                // .png
    'application/pdf',                                                          // .pdf
    'image/webp',                                                               // .webp
    'application/vnd.ms-excel',                                                 // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',        // .xlsx
    'application/xml',                                                          // .xml [if not readable from casual users (RFC 3023, section 3)]
    'text/xml'                                                                  // .xml [if readable from casual users (RFC 3023, section 3)]
  ]

  const fileTypesString = fileTypes.join(',')

  const truncateFileName = (str: string) => { 
    if (str.length < 22) return str 
    return `${str.slice(0, 10)}…${str.slice(-10)}`
  }

  const humanReadableFileSize = (bytes: number, binary = false): string => {
    const base = binary ? 1024 : 1000
    if (bytes < base) {
      return `${bytes} B`
    }
  
    const prefix = binary ? ['Ki', 'Mi', 'Gi'] : ['k', 'M', 'G']
    let unit = -1
    while (Math.abs(bytes) >= base && unit < prefix.length - 1) {
      bytes /= base
      ++unit
    }
    return `${bytes.toFixed(1)} ${prefix[unit]}B`
  }

  const downloadAttachment = (id: string, name?: string) => {
    axiosInstance
      .get(`attachment/${id}/download`, { responseType: 'blob' })
      .then(({ data }) => {
        fileDownload(data, name ? name : `${id}.${mime.extension(data.type)}`)
      })
  }

  const downloadAttachments = (attachments: { id: string; name?: string }[]) => {
    for (const attachment of attachments) downloadAttachment(attachment.id, attachment.name)
  }

  const uploadAttachments = (attachments: File[]): Promise<AxiosResponse<any>> => new Promise((resolve, reject) => {
    if (attachments?.length) {
      const formData = new FormData()
      for (const attachment of attachments) {
        formData.append('attachments', attachment)
      }
  
      axiosInstance.post('attachment', formData).then(resolve).catch(reject)
    } else {
      reject()
    }
  })

  const deleteAttachment = (id: string): Promise<AxiosResponse<any>> => new Promise((resolve, reject) => {
    axiosInstance.delete(`attachment/${id}`).then(resolve).catch(reject)
  })

  return { fileTypes, fileTypesString, truncateFileName, humanReadableFileSize, downloadAttachment, downloadAttachments, uploadAttachments, deleteAttachment }
}