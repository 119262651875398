var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.id)?_c('v-data-table',{staticClass:"m-inventory-user-assignments-vehicles",attrs:{"loading":_vm.state.loading,"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [5, 10] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [(item.vehicle && item.vehicle.details)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"shrink"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'panel.vehicle.view', params: { id: item.vehicle.id } }}},[_c('v-icon',[_vm._v("mdi-car")])],1)],1),_c('div',{staticClass:"grow d-flex flex-column align-start justify-center",staticStyle:{"line-height":"1"}},[_c('div',{staticClass:"shrink font-weight-medium"},[_vm._v(_vm._s(item.vehicle.details.registrationNumber))]),_c('div',{staticClass:"shrink"},[_c('small',{staticClass:"text--secondary"},[_vm._v(_vm._s(item.vehicle.details.vehicleBrand)+" "+_vm._s(item.vehicle.details.vehicleModel))])])])]):_vm._e()]}},{key:"item.handoverDate",fn:function(ref){
var item = ref.item;
return [(item.handoverDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.handoverDate,'DD.MM.YYYY')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.returnDate",fn:function(ref){
var item = ref.item;
return [(item.returnDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.returnDate,'DD.MM.YYYY')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.handoverAttachments",fn:function(ref){
var item = ref.item;
return [(item.handoverAttachments && item.handoverAttachments.length)?_c('a-files-list',{attrs:{"items":item.handoverAttachments}}):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.returnAttachments",fn:function(ref){
var item = ref.item;
return [(item.returnAttachments && item.returnAttachments.length)?_c('a-files-list',{attrs:{"items":item.returnAttachments}}):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(!item.returnDate)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('action', { type: 'vehicleReturn', user: item.user, vehicle: item.vehicle, step: 4 })}}},on),[_c('v-icon',[_vm._v("mdi-link-off")])],1)]}}],null,true)},[_c('span',[_vm._v("Zdanie pojazdu")])]):_vm._e()],1)]}}],null,false,2575224106)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }