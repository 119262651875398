










































import useAttachment from "@/use/attachment";
import { defineComponent, reactive } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    AFilesList: () => import("@/components/atoms/a-files-list.vue"),
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    company: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, { root }) {
    const { downloadAttachment } = useAttachment({ root });

    const state = reactive({
      loaded: false,
      loading: false,
      items: [],
      total: 0,
      headers: [
        { value: "vehicle", text: "Pojazd", sortable: false },
        { value: "handoverDate", text: "Data wydania", sortable: false },
        {
          value: "handoverAttachments",
          text: "Protokoły wydania",
          sortable: false,
        },
        { value: "returnDate", text: "Data zdania", sortable: false },
        {
          value: "returnAttachments",
          text: "Protokoły zdania",
          sortable: false,
        },
        {
          value: "action",
          text: "",
          width: 1,
          sortable: false,
          align: "right",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 5,
      },
    });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      axiosInstance
        .get(`inventory/user/${props.id}/vehicle/history`, {
          params: {
            page: state.options.page || 1,
            itemsPerPage: state.options.itemsPerPage || 5,
            company: props.company || undefined,
          },
        })
        .then(({ data: { assignments, total } }) => {
          state.items = assignments;
          state.total = total;

          state.loaded = true;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;

          state.loaded = false;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced(
      [() => [props.id, props.company], () => state.options],
      fetch,
      {
        deep: true,
        immediate: true,
        debounce: 500,
        maxWait: 5000,
      }
    );

    const refresh = () => fetch();

    return { downloadAttachment, state, refresh };
  },
});
